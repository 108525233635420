import React from "react";
import PropTypes from "prop-types";

import NavLink from "components/menus/NavLink";
import Tooltip from "components/Tooltip";
import { breakpoints } from "helpers/windowDimensions";


// StyleGuide = molecules/global/section

class Section extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tooltipWidth: 0 };
    this.hoistTooltipWidthRef = this.hoistTooltipWidthRef.bind(this);
  }

  hoistTooltipWidthRef(value) {
    this.setState(prevState => ({ ...prevState, tooltipWidth: value }));
  }

  adjustTooltipPosition(name = '') {
    const { windowDimensions } = this.props;
    if (!windowDimensions) return '';
    const { width } = windowDimensions;
    const { tooltipWidth } = this.state; // app/assets/stylesheets/components/_Tooltip.scss
    const arbitraryMargin = (tooltipWidth / 3) + 10;
    const breakpointsArr = Object.values(breakpoints).slice(1);
    return (name.length < 20 && breakpointsArr.some(breakingPoint => (width >= breakingPoint && width < breakingPoint + arbitraryMargin) || width < breakpointsArr[0])) ? 'right' : '';
  }

  renderSection() {
    const {
      children,
      className,
      styleVariant,
      role
    } = this.props;
    return (
      <div role={role} className={`Section section-${styleVariant} w-100 h-100 ${className}`}>
        { children }
      </div>
    );
  }

  render() {
    const {
      name,
      styleVariant,
      notificationQuantity,
      wrapperClassName,
      tooltip,
      tooltipClassName,
      subTitle,
      titleLevel,
      navLinkClassName
    } = this.props;

    if (name || subTitle) {
      const nameContent = typeof name === 'string' ? name : <span>&nbsp;</span>;

      const isFocussable = typeof nameContent === 'string';

      return (
        <div className={`SectionWrapper d-flex flex-column ${wrapperClassName}`}>
          <div>
            { !!name && (
              <NavLink ariaLevel={titleLevel} notificationQuantity={notificationQuantity} styleVariant={styleVariant} className={`d-inline-block ${navLinkClassName}`} active isFocussable={isFocussable}>{ nameContent }</NavLink>
            )}
            {!!tooltip && <Tooltip className={`${tooltipClassName} ${this.adjustTooltipPosition(name)}`} hoistTooltipWidthRef={this.hoistTooltipWidthRef}>{tooltip}</Tooltip> }
            { !!subTitle && <h6 className="ml-2">{subTitle}</h6> }
          </div>
          { this.renderSection() }
        </div>
      );
    }

    return this.renderSection();
  }
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  notificationQuantity: PropTypes.number,
  styleVariant: PropTypes.oneOf([
    'settings',
    'default',
    'QuestionModalTabs',
    'storefront',
    'storefrontMobile'
  ]),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  subTitle: PropTypes.string,
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  tooltipClassName: PropTypes.string,
  role: PropTypes.string,
  titleLevel: PropTypes.number,
  windowDimensions: PropTypes.object,
  navLinkClassName: PropTypes.string
};

Section.defaultProps = {
  className: "",
  wrapperClassName: "",
  styleVariant: "default",
  role: "region"
};

export default Section;
