import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

import Nav from "components/menus/Nav";
import NavLink from "components/menus/NavLink";
import Section from "components/Section";
import Fade from 'components/Fade';


// StyleGuide = organsims/menus/tabbed-sections

class TabbedSections extends React.Component {
  constructor(props) {
    super(props);

    const filteredChildren = Array.isArray(props.children) ? props.children.filter(child => !!child) : props.children;

    this.state = {
      shouldShowTab: props.activeTab || (filteredChildren.length > 0 && filteredChildren[0].props.name)
    };
  }

  componentDidUpdate() {
    const { children, callBack } = this.props;
    const { shouldShowTab } = this.state;

    const filteredChildren = Array.isArray(children) ? children.filter(child => !!child) : children;

    // Ensure that the shouldShowTab is set and included in children
    if (!shouldShowTab || !filteredChildren.map(child => child.props.name).includes(shouldShowTab)) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({
        shouldShowTab: filteredChildren[0].props.name
      });
    }
    if (callBack) callBack(shouldShowTab);
  }

  getTabbedSectionClasses(styleVariant) {
    return classNames('TabbedSections', 'd-flex', {
      'h-100': styleVariant !== 'QuestionModalTabs',
      'flex-column': styleVariant === 'default' || styleVariant === 'QuestionModalTabs' || styleVariant === "storefront",
      'flex-row': styleVariant === 'settings' || styleVariant === 'storefrontMobile'
    });
  }

  getNavClasses(styleVariant) {
    return classNames(`nav-${styleVariant}`, {
      'w-100': styleVariant === 'default' || styleVariant === 'QuestionModalTabs' || styleVariant === "storefront",
      'w-15': styleVariant === 'settings' || styleVariant === 'storefrontMobile',
      'd-block': styleVariant === 'settings' || styleVariant === 'storefrontMobile'
    });
  }

  getTabContentClasses(styleVariant) {
    return classNames('tab-content flex-grow-1 position-relative', {
      'w-100': styleVariant === 'default' || styleVariant === 'QuestionModalTabs' || styleVariant === "storefront",
      'w-85': styleVariant === 'settings' || styleVariant === 'storefrontMobile'
    });
  }

  nav(filteredChildren) {
    const {
      disabled,
      hideEmptyNav,
      styleVariant,
      navHeader,
      highlightedSections,
      customContent,
    } = this.props;
    const { shouldShowTab } = this.state;

    if (hideEmptyNav && filteredChildren.length <= 1) return null;

    const navClasses = this.getNavClasses(styleVariant);

    return (
      <Nav role="tablist" navHeader={navHeader} styleVariant={styleVariant} className={`assignment_tabs ${navClasses}`}>
        { filteredChildren.map(child => (
          <Fragment key={child.props.name}>
            <NavLink
              role="tab"
              notificationQuantity={child.props.notificationQuantity}
              key={child.props.name}
              icon={child.props.icon}
              iconProps={child.props.iconProps}
              onClick={() => this.setState({ shouldShowTab: child.props.name })}
              className={`${shouldShowTab === child.props.name ? "active" : ""} ${highlightedSections[child.props.name] ? "highlight" : ""} ${child.props.buttonClassName}`}
              disabled={disabled.includes(child.props.name)}
              styleVariant={styleVariant}
              active={shouldShowTab === child.props.name}
              ariaControls={child.props.name}
            >
              { child.props.name }
            </NavLink>
            { styleVariant === 'storefrontMobile' && this.renderContentSection(child) }
          </Fragment>
        ))}
        {customContent}
      </Nav>
    );
  }

  renderContentSection = (child) => {
    const { styleVariant } = this.props;
    const { shouldShowTab } = this.state;
    const thisTab = child.props.name;
    const className = classNames([styleVariant === "storefrontMobile" ? "h-auto" : "h-100", {
      'absolute-top w-100': shouldShowTab !== thisTab
    }]);
    return (
      <Fade id={child.props.name} role="tabpanel" hide={shouldShowTab !== thisTab} className={className} key={`${child.props.name}-tab`}>
        <Section
          key={child.props.name}
          className={child.props.className}
          styleVariant={styleVariant}
        >
          { child.props.children }
        </Section>
      </Fade>
    );
  }

  render() {
    const {
      children,
      className,
      styleVariant
    } = this.props;

    const filteredChildren = Array.isArray(children) ? children.filter(child => !!child) : children;

    if (filteredChildren.length === 1) return filteredChildren[0];

    const tabbedSectionClasses = this.getTabbedSectionClasses(styleVariant);
    const tabContentClasses = this.getTabContentClasses(styleVariant);
    return (
      <div className={`${tabbedSectionClasses} ${className}`}>
        { this.nav(filteredChildren) }
        { styleVariant !== 'storefrontMobile'
          && (
            <div className={tabContentClasses} ref={(el) => { this.el = el; }}>
              { filteredChildren.map(child => this.renderContentSection(child)) }
            </div>
          )
        }
      </div>
    );
  }
}

TabbedSections.propTypes = {
  children: PropTypes.node.isRequired,
  styleVariant: PropTypes.oneOf([
    'settings',
    'default',
    'QuestionModalTabs',
    'storefront',
    'storefrontMobile'
  ]),
  navHeader: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  className: PropTypes.string,
  activeTab: PropTypes.string,
  hideEmptyNav: PropTypes.bool,
  disabled: PropTypes.arrayOf(PropTypes.string),
  highlightedSections: PropTypes.object,
  callBack: PropTypes.func,
  customContent: PropTypes.node,
};

TabbedSections.defaultProps = {
  className: "",
  activeTab: "",
  hideEmptyNav: false,
  disabled: [],
  styleVariant: 'default',
  highlightedSections: {}
};

export default TabbedSections;
